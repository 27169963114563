import Form from 'components/Form';

import { Fragment, useState } from 'react';
import { Delete } from '@mui/icons-material';
import {
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import RowContainer from '../../components/RowContainer';
import RowContent from '../../components/RowContent';

import { Ti18nId } from 'hooks/useI18n';
import { IMentalNursingRecord } from 'apis/survey/type';
import { IFormRegister, IFormValues, IFormWatch } from 'routes/Main/type';

import MuiTextField from 'components/Form/MuiTextField';
import SectionTitle from '../../components/SectionTitle';

import { formatStringToDate } from 'utils/formatting';

interface Props extends IFormValues, IFormWatch, IFormRegister {
  disabled?: boolean;
  onRequired: (id: Ti18nId) => void;
  onSuccess: (message: string) => void;
}

const GuardianInfo = (props: Props) => {
  const { disabled, register } = props;

  const infos = [
    { title: '성명', value: 'legal_representative_name' },
    { title: '주민등록번호', value: 'legal_representative_ssn' },
    { title: '주소', value: 'legal_representative_addr' },
    { title: '전화번호', value: 'legal_representative_contact' },
  ];

  return (
    <Fragment>
      <SectionTitle title="법정대리인" />
      <RowContainer xs={11.5} sx={{ margin: '20px 0px 30px 0px' }}>
        {infos.map(({ title, value }) => (
          <RowContent title={title} titleRatio={1.5} childrenRatio={4.5}>
            <Form.MuiTextField
              required={false}
              disabled={disabled}
              {...register(`${value}`)}
            />
          </RowContent>
        ))}
      </RowContainer>
    </Fragment>
  );
};

export default GuardianInfo;
