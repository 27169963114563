export const initialToggleObj = {
  patient_evaluation: false,
  pain: false,
  mental_health: false,
  special: false,
  agreement: false,
  nurse_record: false,
  medical_information: false,
};

export const initialSettingsToggleObj = {
  medical_information: false,
};
